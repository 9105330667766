<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="350" content-class="rounded-xl">
      <v-card class="rounded-xl">
        <v-card-title class="mx-1 justify-center justify-space-between">{{ $t('newPassword.title') }}
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn></v-card-title>
        <v-card-text class="px-8">
          <v-form ref="changePasswordForm" v-model="valid">
            <v-text-field
              v-model="oldPassword"
              type="password"
              :label="$t('newPassword.old')"
              dense
              class="py-2"
              single-line
              validate-on-blur
              :rules="passwordRules"
            ></v-text-field>
            <v-text-field
              v-model="newPassword"
              type="password"
              :label="$t('newPassword.new')"
              dense
              class="py-2"
              single-line
              validate-on-blur
              :rules="passwordRules"
            ></v-text-field>
            <v-text-field
              v-model="newPasswordRepeat"
              type="password"
              :label="$t('newPassword.repeat')"
              dense
              class="py-2"
              single-line
              validate-on-blur
              :rules="passwordRules"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-8">
          <v-btn btn color="primary" block depressed :disabled="submitLoading" :loading="submitLoading" @click="submitHandle" rounded>{{ $t('newPassword.button') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Service from '@/services'
import * as mutationTypes from '@/store/mutation-types'
export default {
  name: 'ChangeCashierPassword',
  props: {
    showSuccessDialog: Function
  },
  data () {
    return {
      dialog: false,
      oldPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
      submitLoading: false,
      valid: true,
      passwordRules: [
        v => !!v.trim() || this.$t('validation.passwordRequired')
      ]
    }
  },
  methods: {
    show () {
      this.dialog = true
    },
    async getCountries () {
      try {
        const { data } = await Service.get('Tenants/?onlyCountries=true')
        this.$store.dispatch('setCountries', data)
      } catch (error) {
      }
    },
    async submitHandle () {
      if (!this.$refs.changePasswordForm.validate()) {
        return
      }
      this.submitLoading = true
      try {
        const { data } = await Service.post('Cashier/ChangeCashierPassword', {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
          newPasswordRepeat: this.newPasswordRepeat
        })

        if (data.token) {
          localStorage.setItem('token', data.token)
          localStorage.setItem('tenantValue', 'PL')
          localStorage.setItem('currency', 'zł')
          this.$store.commit(mutationTypes.LOGIN, true)
          this.dialog = false
          this.showSuccessDialog()
          this.getCountries()
        }

        this.$refs.changePasswordForm.resetValidation()
      } catch (e) {
      } finally {
        this.submitLoading = false
      }
    }
  }
}
</script>
